/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { shape, number, arrayOf, string, func, bool, array, objectOf, oneOf, object, oneOfType } from 'prop-types';
import Hammer from 'hammerjs';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import { getAnswerShapesForCurrentPages } from '../../../../selectors/rendering';
import { getTotalPagesForDigibook } from '../../../../selectors/digibooks';
import { getCurrentTool, getTools, getAnswerStepperMode } from '../../../../selectors/tools';
import { getZoomLevel, getZoomToFitMode } from '../../../../selectors/navigation';
import { getIsRendered, getIsSolutionsPageVisible } from '../../../../selectors/player';

// actions
import { setCurrentPage, revealAnswerAreaFor, hideAnswerAreaFor, disableZoomToFitMode, revealStepperAnswer, hideStepperAnswer, setZoomLevel } from '../../../../actions/navigation';
import { createMarking, createMarkings, setCurrentTool } from '../../../../actions/tools';
import { linkAreaWithMedialinksClicked } from '../../../../actions/dialog';
import { bookLayerRendered } from '../../../../actions/player';

import LinkAreaLinkTypes from '../../../../enums/linkarealinktype';
import ZoomLevel from '../../../../enums/zoomLevel';
import Tools from '../../../../enums/tools';
import Position from '../../../../enums/position';
import shapeActions from '../../../../enums/shapeActions';

import FabricService from '../../services/fabric-service';
import api from '../../../../services/api';

import isOdd from '../../../../utils/isOdd';
import { calculateSpreadForPageNumbers } from '../../../../utils/calculateSpreadForPageNumbers';
import * as Url from '../../../../utils/url';

import Confirmation from '../dialogs/confirmation';
import { PORTAAL_URL } from '../../../../constants/constants';
import spinner from '../../../../../assets/images/spinner.svg';
import iconNoAccess from '../../../../../assets/icons/no-access.svg';
import { SIDEBAR_WIDTH } from '../dialogs/mediaDialogs/chooser';
import AnchorPosition from '../../../../enums/anchorposition';
import TextLayer from './text-selection/text-layer';
import TextAnnotationLayer from './text-annotation';
import { MarkingLayer } from './marking-layer';

const modals = { ERROR: 'error', POPUP: 'popup' };

export class Book extends React.PureComponent {
  constructor(props) {
    super(props);

    this.container = React.createRef();

    this.answerToBlink = undefined;
    this.updatePromise = undefined;

    this.digibookLicenses = {};

    this.state = {
      currentAnswerSet: undefined,
      currentAnswerIndex: undefined,
      openModal: undefined,
      pagesRendered: [],
    };
  }

  componentDidMount() {
    const {
      dimensions: { width, height },
      pageNumbersToShow,
      activeDrawerAnchorSide,
      disableAnswerLayerBlend,
      manualMargins,
      sidebarAnchor,
    } = this.props;

    this.updateExternalDigibookLicenses();
    this.fabric = new FabricService('the-fabric-canvas', pageNumbersToShow.length);
    this.fabric.initialize(height, width, disableAnswerLayerBlend, sidebarAnchor);
    this.fabric.addDragListeners(
      {
        [Tools.ANSWER_REVEAL]: this.objectCreatedHandler,
        [Tools.ANSWER_HIDE]: this.objectCreatedHandler,
        [Tools.ZOOM_SELECT]: this.zoomSelectionCreatedHandler,
        [Tools.SELECTION_ERASER]: this.onShapeDrawn,
      },
      this.updateViewport,
      this.disableZoomToFit,
    );

    this.addSwipeListeners();
    this.fabric.setDrawerOpenSide(activeDrawerAnchorSide);
    this.fabric.addPinchListeners(this.pinchHandler);
    this.fabric.addClickListener(this.addAnnotation, this.setSelectedAnnotationId);
    this.updateViewport();
    if (manualMargins) this.fabric.setManualMargins(manualMargins);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      pageNumbersToShow,
      dimensions,
      currentTool,
      zoomLevel,
      zoomToFitMode,
      answerShapes,
      tools,
      bookPages,
      answerPages,
      manualPages,
      activeDrawerAnchorSide,
      answerStepperMode,
      annotationsForSpread,
      selectedAnnotationId,
      visibleLinkAreas,
      externalDigibookIds,
      scaleCanvasToFit,
      sidebarAnchor,
      isSolutionsPageVisible,
    } = this.props;

    const {
      dimensions: oldDimensions,
      pageNumbersToShow: prevPages,
      zoomLevel: oldZoomLevel,
      zoomToFitMode: wasZoomedToFit,
      answerShapes: prevAnswerShapes,
      tools: prevTools,
      bookPages: prevBookPages,
      answerPages: prevAnswerPages,
      manualPages: prevManualPages,
      activeDrawerAnchorSide: prevActiveDrawerAnchorSide,
      answerStepperMode: prevAnswerStepperMode,
      currentTool: prevTool,
      annotationsForSpread: prevAnnotationsForSpread,
      selectedAnnotationId: prevSelectedAnnotationId,
      visibleLinkAreas: prevVisibleLinkAreas,
      externalDigibookIds: prevExternalDigibookIds,
      sidebarAnchor: prevSidebarAnchor,
    } = prevProps;

    const { currentAnswerIndex, currentAnswerSet } = this.state;
    const { currentAnswerIndex: prevAnswerIndex, currentAnswerSet: prevAnswerSet } = prevState;
    const answerIndexChanged = currentAnswerIndex !== prevAnswerIndex;

    const dimensionsChanged = oldDimensions && (dimensions.width !== oldDimensions.width || dimensions.height !== oldDimensions.height);
    const pageNumbersToShowChanged = pageNumbersToShow !== prevPages;
    const zoomChanged = oldZoomLevel !== zoomLevel;
    const hasZoomedToFit = zoomToFitMode && !wasZoomedToFit;
    const bookPagesChanged = bookPages !== prevBookPages;
    const answerPagesChanged = answerPages !== prevAnswerPages;
    const drawerPositionChanged = activeDrawerAnchorSide !== prevActiveDrawerAnchorSide;
    const answerStepperModeChanged = answerStepperMode !== prevAnswerStepperMode;
    const answersHidden = Object.values(prevAnswerShapes).some(shapes => shapes.length > 0) && Object.values(answerShapes).every(shapes => shapes.length === 0);
    const annotationsChanged = annotationsForSpread !== prevAnnotationsForSpread;
    const selectedAnnotationChanged = selectedAnnotationId !== prevSelectedAnnotationId;
    const answerShapesChanged = answerShapes !== prevAnswerShapes;
    const answersRevealed = Object.values(answerShapes).some(pageArr => pageArr.length > 0);
    const manualPagesChanged = manualPages !== prevManualPages;
    const externalDigibooksChanged = externalDigibookIds.some(id => !prevExternalDigibookIds.includes(id));
    const sidebarAnchorChanged = sidebarAnchor !== prevSidebarAnchor;

    if (sidebarAnchorChanged) this.fabric.setSidebarAnchor(sidebarAnchor);

    if (externalDigibooksChanged) {
      this.updateExternalDigibookLicenses();
    }

    if (pageNumbersToShowChanged) {
      this.fabric.setAmountOfVisiblePages(pageNumbersToShow.length);
      this.showLoader();
    }

    if (scaleCanvasToFit) this.fabric.scaleCanvasToFit();

    if (sidebarAnchorChanged && isSolutionsPageVisible) {
      this.renderLayers();
      this.drawLinks();
      this.renderAnnotations();
      if (answersRevealed) this.clipAnswerPages();
      if (currentAnswerSet) this.setSelectedAnswerSet(undefined, true);

      this.fabric.scaleCanvasToFit();
    }

    if (bookPagesChanged || answerPagesChanged || manualPagesChanged) {
      this.renderLayers();
    }

    if (bookPagesChanged) {
      this.hideLoader();

      this.fabric.scaleCanvasToFit(zoomLevel);

      this.drawLinks();
      this.renderAnnotations();
      if (answersRevealed) this.clipAnswerPages();
      if (currentAnswerSet) this.setSelectedAnswerSet(undefined, true);
    } else if (answerPagesChanged) {
      this.drawLinks();
      this.renderAnnotations();
    } else if (manualPagesChanged) {
      this.drawLinks();
      this.renderAnnotations();
      if (answersRevealed) this.clipAnswerPages();
      if (currentAnswerSet) this.setSelectedAnswerSet(undefined, true);

      this.fabric.scaleCanvasToFit();
    } else if (hasZoomedToFit && this.pagesRendered === pageNumbersToShow) {
      this.fabric.scaleCanvasToFit(zoomLevel);
      this.updateViewport();
    }
    // Place in else block when other cases arises which have to be done when no answerPages or bookPages changed

    if (dimensionsChanged) {
      this.fabric.handleCanvasResize(dimensions, zoomToFitMode);
      this.updateViewport();
    }

    if (currentAnswerSet && answersHidden && currentAnswerIndex !== -1) {
      // differentiate between answer stepper being deselected and answers being hidden.
      this.setSelectedAnswerSet(undefined, true);
    }

    if (zoomChanged) {
      this.fabric.setZoom(zoomLevel);
      this.updateViewport();
    }

    if (zoomLevel === ZoomLevel.BASE_ZOOM_LEVEL && zoomToFitMode && this.pagesRendered === pageNumbersToShow) {
      this.fabric.shiftViewportForDrawer(activeDrawerAnchorSide, this.updateViewport);
    }

    if (drawerPositionChanged) {
      this.fabric.setDrawerOpenSide(activeDrawerAnchorSide);
      this.updateViewport();
    }

    if (answerStepperModeChanged || bookPagesChanged || answerPagesChanged || currentAnswerSet !== prevAnswerSet || answerIndexChanged) {
      this.renderAnswerSetIcons();
    }

    if (!pageNumbersToShowChanged && answerPages.length > 0 && (answerShapesChanged || answerPagesChanged)) {
      this.clipAnswerPages();
      this.drawLinks();
      this.renderAnnotations();
      delete this.answerToBlink;
    }

    if (tools !== prevTools || currentTool !== prevTool) {
      this.fabric.setCurrentTool(currentTool, { ...tools[currentTool] });

      if (!bookPagesChanged) this.setSelectedAnswerSet(undefined, true);
    }

    if (currentTool !== prevTool || annotationsChanged || selectedAnnotationChanged || visibleLinkAreas !== prevVisibleLinkAreas) {
      this.drawLinks();
      this.renderAnnotations();
      this.renderAnswerSetIcons();
    }

    this.fabric.renderAll();
  }

  componentWillUnmount() {
    this.fabric.dispose();
    if (this.eventManager) this.eventManager.destroy();
  }

  addAnnotation = annotation => {
    const { addAnnotation, isSolutionsPageVisible, sidebarAnchor } = this.props;
    const { isSinglePage, isRightPage, isStandalonePage } = this.getSpreadSpecs();
    const isRightPageOnSpread = isRightPage && !isStandalonePage;

    let { left } = annotation;
    const { top } = annotation;

    const isNextToSolutionsPageWithSidebarLeft = isSolutionsPageVisible && sidebarAnchor === Position.LEFT && left <= -0.5 && top >= 0 && top <= 1;
    const isNextToSolutionsPageWithSidebarRight = isSolutionsPageVisible && sidebarAnchor === Position.RIGHT && left >= 0.5 && top >= 0 && top <= 1;

    /**
     * Annotations on the sides of the book always have to be stored correctly.
     * They are indicated trough their left property being:
     * left side: left < 0
     * right side: left > 1
     */
    if (isSinglePage && isRightPageOnSpread && left > 0) left += 0.5;
    if (isSinglePage && !isRightPageOnSpread && left > 0.5) left += 0.5;
    if (isNextToSolutionsPageWithSidebarLeft) left += 0.5;
    if (isNextToSolutionsPageWithSidebarRight) left -= 0.5;

    addAnnotation({
      ...annotation,
      text: '',
      left,
    });
  };

  setSelectedAnnotationId = annotationId => {
    const { setSelectedAnnotationId } = this.props;
    setSelectedAnnotationId(annotationId);
  };

  disableZoomToFit = () => {
    const { userHasPanned, zoomToFitMode } = this.props;

    if (zoomToFitMode) userHasPanned();
  };

  getSpreadSpecs = () => {
    const { pageNumbersToShow, totalPages } = this.props;
    const isSinglePage = pageNumbersToShow.length === 1;
    const isRightPage = pageNumbersToShow[0] !== 1 && isOdd(pageNumbersToShow[0]);
    const isStandalonePage =
      pageNumbersToShow[0] === 0 || // cover
      pageNumbersToShow[0] === 1 || // first page
      pageNumbersToShow[0] === totalPages + 1 || // backcover
      (pageNumbersToShow[0] === totalPages && !isRightPage); // last page

    return {
      isSinglePage,
      isRightPage,
      isStandalonePage,
    };
  };

  setSelectedAnswerSet = (setId, forceUpdate) => {
    const { currentTool } = this.props;
    const { currentAnswerSet } = this.state;

    if (currentTool === Tools.POINTER || forceUpdate) {
      this.setState({ currentAnswerSet: currentAnswerSet === setId ? undefined : setId, currentAnswerIndex: undefined });
    }
  };

  renderAnswerSetIcons = () => {
    const { visibleAnswerSets, answerStepperMode } = this.props;
    const { currentAnswerIndex, currentAnswerSet } = this.state;
    const { isStandalonePage, isSinglePage, isRightPage } = this.getSpreadSpecs();

    if (answerStepperMode) {
      this.fabric.renderAnswerSteppers(
        visibleAnswerSets,
        isStandalonePage,
        isSinglePage,
        isRightPage,
        currentAnswerSet,
        currentAnswerIndex,
        this.setSelectedAnswerSet,
        this.incrementCurrentAnswerIndex,
        this.decrementCurrentAnswerIndex,
      );
    } else {
      this.fabric.cleanUpAnswerSteppers();
    }
  };

  onShapeDrawn = drawnShape => {
    const { markingCreated, pageNumbersToShow, totalPages } = this.props;
    const { isSinglePage, isRightPage } = this.getSpreadSpecs();
    const shapeToAdd = this.fabric.offsetDrawings(drawnShape, isSinglePage, isRightPage);

    const clonedShape = shapeToAdd.toObject();
    clonedShape.meta = shapeToAdd.meta;

    markingCreated(clonedShape, calculateSpreadForPageNumbers(pageNumbersToShow, totalPages));
  };

  renderLayers = () => {
    const { bookPages, answerPages, bookRendered, pageNumbersToShow, manualPages, isSolutionsPageVisible } = this.props;

    this.fabric.clearPageCache();
    this.fabric.removeAllObjects();

    bookPages.forEach((image, index) => {
      const position = index === 0 ? Position.LEFT : Position.RIGHT;
      this.fabric.renderBookPage(image, position, this.determineLeftOrRightPosition(pageNumbersToShow[index]), isSolutionsPageVisible);
    });

    answerPages.forEach((image, index) => {
      const position = index === 0 ? Position.LEFT : Position.RIGHT;
      this.fabric.cacheAnswerPage(image, position, isSolutionsPageVisible);
    });

    manualPages.forEach((image, index) => {
      const position = index === 0 ? Position.LEFT : Position.RIGHT;

      const { isRightPage } = this.getSpreadSpecs();

      if (image !== null) {
        this.fabric.renderManualPage(image, position, isRightPage, manualPages.filter(x => x !== null).length, isSolutionsPageVisible);
      }
    });

    if (bookPages.length > 0) {
      bookRendered(this.fabric.getSinglePageDimensions());
      this.pagesRendered = pageNumbersToShow;
      this.setState({ pagesRendered: pageNumbersToShow });
    }
  };

  drawLinks = () => {
    const { visibleLinkAreas, currentTool } = this.props;
    const { isStandalonePage, isSinglePage, isRightPage } = this.getSpreadSpecs();

    const applyEventsToLinks = currentTool !== Tools.ANNOTATION;

    this.fabric.renderAreasAndAddListeners(visibleLinkAreas, isStandalonePage, isSinglePage, isRightPage, this.linkAreaClickHandler, applyEventsToLinks);
  };

  objectCreatedHandler = rect => {
    const { revealArea, hideArea, pageNumbersToShow, currentTool, totalPages } = this.props;
    const { isSinglePage, isRightPage } = this.getSpreadSpecs();
    const shapeToAdd = this.fabric.offsetShape(rect, isSinglePage, isRightPage);

    switch (currentTool) {
      case Tools.ANSWER_REVEAL: {
        revealArea(shapeToAdd, pageNumbersToShow, totalPages);
        break;
      }
      case Tools.ANSWER_HIDE: {
        hideArea(shapeToAdd, pageNumbersToShow, totalPages);
        break;
      }
      default:
        hideArea(shapeToAdd, pageNumbersToShow, totalPages);
        break;
    }
  };

  zoomSelectionCreatedHandler = nextZoom => {
    const { setZoom, setTool } = this.props;

    setZoom(nextZoom);
    setTool(Tools.POINTER);
  };

  saveTextHighlights = shapes => {
    const { markingsCreated, pageNumbersToShow, totalPages } = this.props;

    const rects = FabricService.getSelectionShapes(shapes);
    markingsCreated(rects, calculateSpreadForPageNumbers(pageNumbersToShow, totalPages));
  };

  renderTempHighlights = shapes => {
    const rects = FabricService.getSelectionShapes(shapes);

    this.fabric.renderTempHighlights(rects);
  };

  linkAreaClickHandler = async opt => {
    const { visibleLinkAreas, setPage, digibookId: currentDigibookId, currentTool, openMedialinksForLinkArea } = this.props;
    const linkArea = visibleLinkAreas.find(x => x.id === opt.target.linkAreaId);
    const { linkType, url, digibookLink, name } = linkArea;

    opt.e.preventDefault();

    if (currentTool === Tools.POINTER) {
      switch (linkType) {
        case LinkAreaLinkTypes.URL: {
          return this.openInNewtabWithFallBack(url, name);
        }
        case LinkAreaLinkTypes.DIGIBOOK: {
          const { captureSameDigibook, captureExternalDigibook } = this.props;
          const { superModuleId, digibook, pageNumber } = digibookLink;

          // same digibook
          if (currentDigibookId === digibook) {
            captureSameDigibook();
            return setPage(pageNumber);
          }

          // check license if they are loaded, otherwise just continue
          if (this.digibookLicenses[digibook] === false) {
            return this.setState({ openModal: { type: modals.ERROR } });
          }

          // open external digibook
          await captureExternalDigibook(digibook, superModuleId);
          const superModuleQ = superModuleId ? `?superModuleId=${superModuleId}` : '';
          const path = `/digibook/${digibook}/${pageNumber}${superModuleQ}`;
          return this.openInNewtabWithFallBack(path, name);
        }
        case LinkAreaLinkTypes.MEDIALINKS:
          {
            const { sidebarAnchor } = this.props;
            const sidebarOffset = sidebarAnchor === AnchorPosition.LEFT ? SIDEBAR_WIDTH : 0;
            const isTouch = opt.e.type && opt.e.type.indexOf('touch') > -1;
            const x = isTouch ? opt.e.changedTouches[0].clientX : opt.e.offsetX + sidebarOffset;
            const y = isTouch ? opt.e.changedTouches[0].clientY : opt.e.offsetY;
            openMedialinksForLinkArea(linkArea, { x, y });
          }
          break;
        default: {
          // ignore
        }
      }
    }

    return false;
  };

  updateViewport = () => {
    const { viewPortTransform } = this.state;
    const fabricVpt = this.fabric.getViewportTransform();
    document.dispatchEvent(
      new CustomEvent('canvas-panned', {
        detail: fabricVpt,
      }),
    );

    if (!viewPortTransform || viewPortTransform.some((item, i) => item !== fabricVpt[i])) {
      this.setState({ viewPortTransform: [...fabricVpt] });
    }
  };

  decrementCurrentAnswerIndex = () => {
    const { visibleAnswerSets, stepperHide } = this.props;
    const { currentAnswerSet, currentAnswerIndex } = this.state;
    const newAnswerIndex = (currentAnswerIndex || 0) - 1;

    if (newAnswerIndex >= -1) {
      const current = visibleAnswerSets.find(x => x.id === currentAnswerSet);
      this.setState({ currentAnswerIndex: newAnswerIndex });
      stepperHide(current, currentAnswerIndex);
    }
  };

  incrementCurrentAnswerIndex = () => {
    const { visibleAnswerSets, stepperReveal } = this.props;
    const { currentAnswerSet, currentAnswerIndex } = this.state;

    const newAnswerIndex = currentAnswerIndex === undefined ? 0 : currentAnswerIndex + 1;

    const current = visibleAnswerSets.find(x => x.id === currentAnswerSet);
    if (current.answers && newAnswerIndex <= current.answers.length - 1) {
      stepperReveal(current, newAnswerIndex);
      this.answerToBlink = `${currentAnswerSet}-${newAnswerIndex}`;
      this.setState({ currentAnswerIndex: newAnswerIndex });
    }
  };

  pinchHandler = zoomFactor => {
    const { setZoom } = this.props;
    setZoom(zoomFactor);
    this.updateViewport();
  };

  updateExternalDigibookLicenses = async () => {
    const { externalDigibookIds } = this.props;

    const unknownDigibooks = externalDigibookIds.filter(id => this.digibookLicenses[id] === undefined);

    if (unknownDigibooks && unknownDigibooks.length > 0) {
      // Apparently this magically turns your query param into an array in the backend.
      const query = unknownDigibooks.map(id => `digibookIds=${id}&`).join('');
      const {
        data: { data: licenseData },
      } = await api.get(`/studio/digibooks/license/check?${query}`);

      unknownDigibooks.forEach(id => {
        const x = licenseData.find(el => el.id === id);
        this.digibookLicenses[id] = x ? x.hasLicense : false;
      });
    }
  };

  // this should be the only correct position determination for the pages
  determineLeftOrRightPosition = pageNumber => {
    const { totalPages } = this.props;

    const standAloneLeftPage =
      pageNumber === 0 || // cover
      pageNumber === 1 || // first page
      pageNumber === totalPages + 1; // backcover

    if (isOdd(pageNumber) && !standAloneLeftPage) return 'right';

    return 'left';
  };

  openInNewtabWithFallBack(url, name) {
    const newTab = window.open(url, '_blank');
    if (newTab) return newTab;
    // opening of the external url failed. Happens sometimes on iOS devices. (timing issue?)
    // If that happened, we will present a popup to the user with a link, that will never fail!
    return this.setState({
      openModal: {
        type: modals.POPUP,
        name,
        url,
      },
    });
  }

  addSwipeListeners() {
    const { swipeLeft, swipeRight } = this.props;
    this.eventManager = new Hammer(this.container.current);

    this.eventManager.get('swipe').set({ velocity: 1, direction: Hammer.DIRECTION_HORIZONTAL });

    this.eventManager.on('swipeleft', e => {
      const { currentTool } = this.props;
      const upperCanvas = this.fabric.fabricCanvas.upperCanvasEl;

      if (e.pointerType !== 'mouse' && e.target === upperCanvas && currentTool === Tools.POINTER) {
        swipeLeft();
      }
    });
    this.eventManager.on('swiperight', e => {
      const { currentTool } = this.props;
      const upperCanvas = this.fabric.fabricCanvas.upperCanvasEl;

      if (e.pointerType !== 'mouse' && e.target === upperCanvas && currentTool === Tools.POINTER) {
        swipeRight();
      }
    });
  }

  clipAnswerPages() {
    const { answerShapes } = this.props;
    const { isSinglePage, isRightPage } = this.getSpreadSpecs();
    this.fabric.clipAnswers(answerShapes, isSinglePage, isRightPage, this.answerToBlink);
  }

  showLoader() {
    this.fabric.removeAllObjects();
    this.setState({ isLoading: true });
  }

  hideLoader() {
    this.setState({ isLoading: false });
  }

  renderAnnotations() {
    const { annotationsForSpread, t, selectedAnnotationId, saveAnnotations, selectedAnnotationRef, isSolutionsPageVisible } = this.props;
    const { isSinglePage, isRightPage, isStandalonePage } = this.getSpreadSpecs();
    const isRightPageOnSpread = isRightPage && !isStandalonePage;
    const placeHolderText = t('annotationTool.placeHolder');

    this.fabric.renderAnnotations(
      annotationsForSpread.filter(x => x.fontId), // temporarily filter only old annotations.
      selectedAnnotationId,
      placeHolderText,
      isSinglePage,
      isRightPageOnSpread,
      saveAnnotations,
      selectedAnnotationRef,
      isSolutionsPageVisible,
    );
  }

  renderConfirmationModal() {
    const { t } = this.props;
    const { openModal = {} } = this.state;

    switch (openModal.type) {
      case modals.ERROR:
        return (
          <Confirmation
            title={t('noAccessConfirmation.title')}
            icon={<span className="icon-no-access" style={{ WebkitMaskImage: `url(${iconNoAccess})`, maskImage: `url(${iconNoAccess})` }} />}
            message={t('noAccessConfirmation.message', {
              myAccountLink: Url.join(PORTAAL_URL, t('portaalRoutes.myAccount')),
            })}
            cancellationText={t('noAccessConfirmation.buttons.cancel')}
            onCancel={() => {
              this.setState({ openModal: undefined });
            }}
          />
        );
      case modals.POPUP: {
        const title = `${t('externalLinkModal.title')} ${openModal.name}`;
        const message = `${t('externalLinkModal.messagePartOne')} '${openModal.name}' ${t('externalLinkModal.messagePartTwo')}`;
        return (
          <Confirmation
            title={title}
            icon="icon-exit"
            message={message}
            cancellationText={t('externalLinkModal.buttons.cancel')}
            confirmationText={t('externalLinkModal.buttons.confirm')}
            onCancel={() => {
              this.setState({ openModal: undefined });
            }}
            onConfirm={() => {
              window.open(openModal.url, '_blank');
              this.setState({ openModal: undefined });
            }}
          />
        );
      }
      default:
        return null;
    }
  }

  render() {
    const {
      t,
      bookText,
      bookPages,
      currentTool,
      answerText,
      visibleAnswerSets,
      answerStepperMode,
      visibleLinkAreas,
      isRendered,
      sidebarAnchor,
      isSolutionsPageVisible,
    } = this.props;
    const { isLoading, viewPortTransform, pagesRendered } = this.state;

    const { isSinglePage, isRightPage } = this.getSpreadSpecs();
    const bookWidth = bookPages.reduce((sum, page) => sum + page?.width, 0);
    const bookHeight = bookPages[0]?.height || 0;

    return (
      <div className="book canvas-wrapper" ref={this.container}>
        {isLoading && (
          <div className="pbb-book-loader">
            <img src={spinner} alt={t('book.loading')} />
          </div>
        )}
        <div>
          <canvas id="the-fabric-canvas" className={classNames({ 'pbb-book-canvas--loading': isLoading })} />
          {bookText.length > 0 && isRendered && (currentTool === Tools.POINTER || currentTool === Tools.TEXT_MARKER) && (
            <TextLayer
              id="book"
              text={bookText}
              answerText={answerText}
              viewPortTransform={viewPortTransform}
              bookDimensions={{ width: bookWidth, height: bookHeight }}
              linkAreas={visibleLinkAreas}
              answerSets={answerStepperMode ? visibleAnswerSets : []}
              isSinglePage={isSinglePage}
              isRightPage={isRightPage}
              saveTextHighlights={this.saveTextHighlights}
              renderTempHighlights={this.renderTempHighlights}
              pageWidth={bookPages[0]?.width || 0}
              sidebarAnchor={sidebarAnchor}
            />
          )}
          {bookText.length > 0 && isRendered && currentTool === Tools.POINTER && isSolutionsPageVisible && viewPortTransform && (
            <TextLayer
              id="solutions"
              text={bookText}
              answerText={answerText}
              bookDimensions={{ width: bookWidth, height: bookHeight }}
              isSinglePage={isSinglePage}
              isRightPage={isRightPage}
              saveTextHighlights={this.saveTextHighlights}
              renderTempHighlights={this.renderTempHighlights}
              pageWidth={bookPages[0]?.width || 0}
              sidebarAnchor={sidebarAnchor}
              viewPortTransform={[
                viewPortTransform[0],
                viewPortTransform[1],
                viewPortTransform[2],
                viewPortTransform[3],
                viewPortTransform[4] - bookWidth * viewPortTransform[0],
                viewPortTransform[5],
              ]}
            />
          )}
          {viewPortTransform && bookPages.length && <TextAnnotationLayer viewportTransform={viewPortTransform} pageWidth={bookPages[0].width} pageHeight={bookPages[0].height} />}
          {bookPages && viewPortTransform && <MarkingLayer bookPages={bookPages} isLoading={isLoading} pagesRendered={pagesRendered} viewportTransform={viewPortTransform} />}
        </div>
        {this.renderConfirmationModal()}
      </div>
    );
  }
}
const getListOfExternalDigibookIds = (linkAreas, digibookId) =>
  (linkAreas || [])
    .filter(linkarea => linkarea.linkType === 'digibook')
    .map(linkarea => linkarea.digibookLink.digibook)
    .filter(id => id !== digibookId)
    .reduce((acc, cur) => (acc.includes(cur) ? acc : [...acc, cur]), []);

const mapStateToProps = (state, { digibookId, visibleLinkAreas }) => ({
  currentTool: getCurrentTool(state),
  answerShapes: getAnswerShapesForCurrentPages(state),
  zoomLevel: getZoomLevel(state),
  zoomToFitMode: getZoomToFitMode(state),
  tools: getTools(state),
  answerStepperMode: getAnswerStepperMode(state),
  totalPages: getTotalPagesForDigibook(state),
  externalDigibookIds: getListOfExternalDigibookIds(visibleLinkAreas, digibookId),
  isRendered: getIsRendered(state),
  isSolutionsPageVisible: getIsSolutionsPageVisible(state),
});

const mapDispatchToProps = {
  setPage: setCurrentPage,
  userHasPanned: disableZoomToFitMode,
  revealArea: revealAnswerAreaFor,
  hideArea: hideAnswerAreaFor,
  markingCreated: createMarking,
  markingsCreated: createMarkings,
  openMedialinksForLinkArea: linkAreaWithMedialinksClicked,
  bookRendered: bookLayerRendered,
  stepperReveal: revealStepperAnswer,
  stepperHide: hideStepperAnswer,
  setZoom: setZoomLevel,
  setTool: setCurrentTool,
};

Book.propTypes = {
  // Own Props
  digibookId: string.isRequired,
  dimensions: shape({
    height: number.isRequired,
    width: number.isRequired,
  }).isRequired,
  pageNumbersToShow: arrayOf(number),
  bookPages: array,
  answerPages: array,
  manualPages: array,
  manualMargins: shape({
    top: number.isRequired,
    left: number.isRequired,
    height: number.isRequired,
    width: number.isRequired,
  }),
  activeDrawerAnchorSide: string,
  visibleLinkAreas: arrayOf(
    shape({
      linkType: string,
      url: string,
      digibookLink: shape({
        digibook: string.isRequired,
        pageNumber: number.isRequired,
      }),
    }),
  ),
  scaleCanvasToFit: bool,

  // Connected Props
  currentTool: string,
  answerShapes: objectOf(
    arrayOf(
      shape({
        width: number,
        height: number,
        left: number,
        top: number,
        action: oneOf([shapeActions.HIDE, shapeActions.REVEAL]),
        id: string,
      }),
    ),
  ),
  visibleAnswerSets: arrayOf(
    shape({
      id: string,
      shape: shape({
        top: number,
        left: number,
        size: number,
      }),
      answers: arrayOf(
        oneOfType([
          shape({
            top: number,
            left: number,
            width: number,
            height: number,
          }),
          shape({
            members: arrayOf({
              top: number,
              left: number,
              width: number,
              height: number,
            }),
          }),
        ]),
      ),
    }),
  ),
  zoomLevel: number.isRequired,
  zoomToFitMode: bool,
  tools: shape({
    annotation: shape({
      color: string.isRequired,
      size: string.isRequired,
      backgroundColor: string.isRequired,
    }),
    pencil: shape({
      color: string.isRequired,
      size: string.isRequired,
    }),
    marker: shape({
      color: string.isRequired,
      size: string.isRequired,
    }),
  }).isRequired,
  answerStepperMode: bool,
  totalPages: number,
  setPage: func.isRequired,
  userHasPanned: func.isRequired,
  revealArea: func.isRequired,
  hideArea: func.isRequired,
  markingCreated: func.isRequired,
  markingsCreated: func.isRequired,
  openMedialinksForLinkArea: func.isRequired,
  bookRendered: func.isRequired,
  stepperReveal: func.isRequired,
  stepperHide: func.isRequired,
  setZoom: func.isRequired,
  setTool: func.isRequired,
  t: func.isRequired,
  sidebarAnchor: oneOf([AnchorPosition.LEFT, AnchorPosition.RIGHT]).isRequired,
  externalDigibookIds: arrayOf(String).isRequired,
  disableAnswerLayerBlend: bool,
  annotationsForSpread: arrayOf(
    shape({
      id: string.isRequired,
      height: number,
      width: number,
      text: string.isRequired,
    }),
  ),
  addAnnotation: func.isRequired,
  selectedAnnotationId: string,
  setSelectedAnnotationId: func.isRequired,
  saveAnnotations: func.isRequired,
  selectedAnnotationRef: shape({
    current: shape({
      id: string.isRequired,
      height: number,
      width: number,
      text: string.isRequired,
    }),
  }),
  bookText: arrayOf(object),
  answerText: arrayOf(object),
  isRendered: bool.isRequired,
  swipeLeft: func.isRequired,
  swipeRight: func.isRequired,
  captureSameDigibook: func.isRequired,
  captureExternalDigibook: func.isRequired,
  isSolutionsPageVisible: bool,
};

Book.defaultProps = {
  pageNumbersToShow: [0],
  currentTool: undefined,
  answerShapes: {},
  zoomToFitMode: true,
  bookPages: [],
  answerPages: [],
  manualPages: [],
  manualMargins: undefined,
  activeDrawerAnchorSide: undefined,
  answerStepperMode: false,
  totalPages: undefined,
  disableAnswerLayerBlend: false,
  annotationsForSpread: [],
  selectedAnnotationId: undefined,
  visibleLinkAreas: [],
  visibleAnswerSets: [],
  selectedAnnotationRef: undefined,
  bookText: [],
  answerText: [],
  isSolutionsPageVisible: false,
  scaleCanvasToFit: false,
};

export const ConnectedBook = connect(mapStateToProps, mapDispatchToProps)(Book);

export default withTranslation()(ConnectedBook);
