/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useCallback, useContext, useRef } from 'react';
import Draggable from 'react-draggable';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getCurrentTool } from '../../../../../selectors/tools';
import JoditEditor from './jodit-editor';
import tools from '../../../../../enums/tools';
import { getIsSolutionsPageVisible } from '../../../../../selectors/player';
import { UserSettingsContext } from '../../../context/user-settings-context';
import anchorposition from '../../../../../enums/anchorposition';
import useSpreadSpecs from './hooks/use-spread-specs';
import { getEventPositions } from './utils';
import useVisibleAnnotations from './hooks/use-annotations';
import useAnnotationLeftPosition from './hooks/use-annotation-left-position';

const DEFAULT_TEXT = '<p><br></p>';

export default function TextAnnotationLayer({ viewportTransform, pageWidth, pageHeight, saveTimeout }) {
  const wrapper = useRef(null);
  const clickRef = useRef(null);

  const currentTool = useSelector(getCurrentTool);
  const isSolutionsPageVisible = useSelector(getIsSolutionsPageVisible);
  const { sidebarAnchor } = useContext(UserSettingsContext);

  const editorRef = useRef(null);

  const { isSinglePage, isRightPage, isStandalonePage } = useSpreadSpecs();

  const [spreadAnnotations, addAnnotation, editAnnotation, setSelectedAnnotationId, selectedAnnotation] = useVisibleAnnotations({ saveTimeout, pageWidth });

  const { getAnnotationLeft } = useAnnotationLeftPosition({ pageWidth, pageHeight });

  function handleAnnotationAdd(e) {
    if (e.target !== wrapper.current || currentTool !== tools.TEXT_ANNOTATION || clickRef.current !== wrapper.current) return;

    const id = shortid.generate();
    const { left, top } = getEventPositions(
      {
        top: e.clientY,
        left: e.clientX,
      },
      {
        viewportTransform,
        isSinglePage,
        isRightPageOnSpread: isRightPage && !isStandalonePage,
        isSolutionsPageVisible,
        sidebarAnchor,
        pageWidth,
        pageHeight,
      },
    );

    const newAnnotation = {
      id,
      text: DEFAULT_TEXT,
      top,
      left,
      resized: false,
      isEdited: false,
    };

    addAnnotation(newAnnotation);
  }

  function handleAnnotationMove(e, { node }) {
    if (!selectedAnnotation) return;

    const { left, top, isOnSolutionsPage } = getEventPositions(
      {
        top: node.getBoundingClientRect().top,
        left: node.getBoundingClientRect().left,
      },
      {
        viewportTransform,
        isSinglePage,
        isRightPageOnSpread: isRightPage && !isStandalonePage,
        isSolutionsPageVisible,
        sidebarAnchor,
        pageWidth,
        pageHeight,
      },
    );

    if (isOnSolutionsPage) return;

    editAnnotation({ ...selectedAnnotation, top, left });
  }

  const handleAnnotationResize = useCallback(
    (width, height) => {
      editAnnotation({ ...selectedAnnotation, width, height, resized: true });
    },
    [editAnnotation, selectedAnnotation],
  );

  function updateActiveAnnotation(annotation) {
    setSelectedAnnotationId(annotation.id);
  }

  return (
    <div
      data-testid="text-annotation-layer"
      ref={wrapper}
      className={classNames('text-wrapper', {
        'text-wrapper--interactive': currentTool === tools.TEXT_ANNOTATION,
      })}
      onMouseDown={e => {
        clickRef.current = e.target;
      }}
      onTouchStart={e => {
        clickRef.current = e.target;
      }}
      onClick={handleAnnotationAdd}
    >
      <div
        style={{
          position: 'absolute',
          transform: `matrix(${viewportTransform.join(',')})`,
        }}
      >
        {isSolutionsPageVisible && (
          // This avoids click events going through the solutions page
          <div
            className="solutions-page"
            style={{
              position: 'absolute',
              top: 0,
              left: sidebarAnchor === anchorposition.LEFT ? -pageWidth : pageWidth,
              width: pageWidth,
              height: pageHeight,
            }}
          />
        )}
        {spreadAnnotations
          .filter(x => !x.fontId) // only new annotations
          .map(annotation => (
            <Draggable
              key={annotation.id}
              onStop={handleAnnotationMove}
              position={{
                y: annotation.top,
                x: getAnnotationLeft(annotation),
              }}
              scale={viewportTransform[0]}
              handle=".handlebar"
            >
              <div className="annotation-wrapper" data-testid={`annotation-${annotation.id}`}>
                {annotation.id === selectedAnnotation?.id ? (
                  <JoditEditor
                    ref={editorRef}
                    annotation={annotation}
                    onChange={updatedProps => editAnnotation({ ...annotation, isEdited: true, ...updatedProps })}
                    onResize={handleAnnotationResize}
                    scale={viewportTransform[0]}
                  />
                ) : (
                  <div
                    className="text-annotation-item"
                    style={{
                      height: annotation.resized ? `${annotation.height}px` : 'auto',
                      width: annotation.resized ? `${annotation.width}px` : 'max-content',
                    }}
                    onClick={() => updateActiveAnnotation(annotation)}
                    dangerouslySetInnerHTML={{ __html: annotation.text }}
                  />
                )}
              </div>
            </Draggable>
          ))}
      </div>
    </div>
  );
}

TextAnnotationLayer.propTypes = {
  viewportTransform: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageWidth: PropTypes.number.isRequired,
  pageHeight: PropTypes.number.isRequired,
  saveTimeout: PropTypes.number,
};

TextAnnotationLayer.defaultProps = {
  saveTimeout: undefined,
};
