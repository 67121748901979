import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { getIsSolutionsPageVisible } from '../../../../../../selectors/player';
import useSpreadSpecs from './use-spread-specs';
import { UserSettingsContext } from '../../../../context/user-settings-context';
import { getAnnotationLeftPosition } from '../utils';

export default function useAnnotationLeftPosition({ pageWidth, pageHeight }) {
  const isSolutionsPageVisible = useSelector(getIsSolutionsPageVisible);
  const { isSinglePage, isRightPage, isStandalonePage } = useSpreadSpecs();
  const { sidebarAnchor } = useContext(UserSettingsContext);

  const getAnnotationLeft = useCallback(
    annotation =>
      getAnnotationLeftPosition(annotation, {
        isSinglePage,
        isRightPage,
        isStandalonePage,
        isSolutionsPageVisible,
        sidebarAnchor,
        pageWidth,
        pageHeight,
      }),
    [isRightPage, isSinglePage, isSolutionsPageVisible, isStandalonePage, pageWidth, pageHeight, sidebarAnchor],
  );

  return { getAnnotationLeft };
}
